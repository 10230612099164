import { LoadingOverlay } from "@mantine/core";
import ErrorHandler from "apps/front-office/design-system/components/ErrorHandler";

export type LoadingErrorHandlerProps = {
  /**
   * Error to be handled
   */
  error?: any;
  /**
   * Is loading component
   */
  isLoading?: boolean | undefined;
};

export default function LoadingErrorHandler({
  error = null,
  isLoading,
}: LoadingErrorHandlerProps) {
  if (isLoading === true) {
    // return (
    //   <>
    //     <Skeleton height={8} radius="xl" />
    //     <Skeleton height={8} mt={6} radius="xl" />
    //     <Skeleton height={8} mt={6} width="70%" radius="xl" />
    //     <Skeleton height={8} mt={12} radius="xl" />
    //     <Skeleton height={8} mt={6} radius="xl" />
    //     <Skeleton height={8} mt={6} width="70%" radius="xl" />
    //     <Skeleton height={8} mt={22} radius="xl" />
    //     <Skeleton height={8} mt={6} radius="xl" />
    //     <Skeleton height={8} mt={6} width="70%" radius="xl" />
    //     <Skeleton height={8} mt={12} radius="xl" />
    //     <Skeleton height={8} mt={6} radius="xl" />
    //     <Skeleton height={8} mt={6} width="70%" radius="xl" />
    //     <Skeleton height={8} mt={22} radius="xl" />
    //     <Skeleton height={8} mt={6} radius="xl" />
    //     <Skeleton height={8} mt={6} width="70%" radius="xl" />
    //     <Skeleton height={8} mt={12} radius="xl" />
    //     <Skeleton height={8} mt={6} radius="xl" />
    //     <Skeleton height={8} mt={6} width="70%" radius="xl" />
    //     <Skeleton height={8} mt={22} radius="xl" />
    //     <Skeleton height={8} mt={6} radius="xl" />
    //     <Skeleton height={8} mt={6} width="70%" radius="xl" />
    //     <Skeleton height={8} mt={12} radius="xl" />
    //     <Skeleton height={8} mt={6} radius="xl" />
    //     <Skeleton height={8} mt={6} width="70%" radius="xl" />
    //   </>
    // );

    return (
      <LoadingOverlay
        visible
        loaderProps={{
          type: "oval",
          color: "primary",
          // children: trans("loading"),
        }}
      />
    );
  }

  if (error) {
    return <ErrorHandler error={error} />;
  }

  return null;
}
