import { hazelnut } from "@mentoor.io/hazelnut-client";
import { trans } from "@mongez/localization";
import Is from "@mongez/supportive-is";

export default function parseError(error: any) {
  if (!error?.response && error instanceof Error) {
    hazelnut.error(error);
  }

  if (Is.empty(error)) {
    return Is.object(error) ? <span>{trans("somethingWentWrong")}</span> : null;
  }

  if (error.response) {
    error = error.response;
  }

  if ([405, 500, 401].includes(error.status)) {
    return <span>{trans(error.data?.error)}</span>;
  }

  if (error.status === 404) {
    return <span>{trans("notFound")}</span>;
  }

  if (error.status === 400) {
    const badRequestErrors = error.data?.messages;

    if (error.data?.error) return error.data?.error;

    const errorMessages = badRequestErrors.map(badRequestError => (
      <span key={badRequestError.key}>{trans(badRequestError.error)}</span>
    ));

    return <div>{errorMessages}</div>;
  }

  if (error?.data?.errors) {
    error = error.data.errors;
  }

  if (error?.data?.error) {
    error = error.data.error;
  }

  let errorContent: any;

  if (Is.array(error)) {
    errorContent = error.map((error: any) => {
      if (error.value) {
        return <div key={error.key}>{error.value}</div>;
      }

      return error;
    });
  } else if (error.message) {
    errorContent = <div>{error.message}</div>;
  } else {
    errorContent = <div>{error}</div>;
  }

  return errorContent;
}

export function parseErrorPlain(error: any) {
  // this function should return a string or an array of objects
  // it will be used to send the error type for tracking

  if (Is.empty(error)) {
    return Is.object(error) ? trans("somethingWentWrong") : null;
  }

  if (error.response) {
    error = error.response;
  }

  if ([405, 500, 401].includes(error.status)) {
    return trans(error.data?.error) || error.statusText;
  }

  if (error.status === 404) {
    return trans("notFound");
  }

  if (error.status === 400) {
    const badRequestErrors = error.data?.messages;

    if (error.data?.error) return error.data?.error;

    return badRequestErrors;
  }

  if (error?.data?.errors) {
    error = error.data.errors;
  }

  if (error?.data?.error) {
    error = error.data.error;
  }

  if (Is.array(error)) {
    return error;
  } else if (error.message) {
    return error.message;
  } else {
    return error;
  }
}
